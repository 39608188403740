.page
  min-height: 100vh
  box-sizing: border-box

  &.has-background-cover
    background-size: cover
    background-position: center

  /*
    Header and Title 
  /*---------------------------------------*/
  &__header
    margin-bottom: $mg*2

  &__title
    margin: 0
    font-weight: bold
    text-align: center


  /*
    Inner 
  /*---------------------------------------*/
  &__inner
    padding: $mg*2
    background-color: rgba(white, .8)

  /*
    Body 
  /*---------------------------------------*/
  &__body
    max-width: $maxWidth
    // margin: $gutter*4 auto
    // box-sizing: border-box
    color: $font



