.footer
  width: 100%
  box-sizing: border-box
  text-align: center
  line-height: 1.3
  opacity: .8

  &.is-home
    position: absolute
    bottom: 30px

  &.is-white
    a
      color: white
      &:hover
        color: white
  &.is-product
    position: absolute
    bottom: 30px
    left: 50%
    transform: translate(-50%, 0)

  a
    color: $font
    font-size: .8rem
    opacity: .8
    &:hover
      text-decoration: none
      opacity: 1
      color: $font
