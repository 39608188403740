.product

  /*
    Header and Title 
  /*---------------------------------------*/


  /*
    Item 
  /*---------------------------------------*/
  &-item
    &__header
      margin-bottom: $mg

    &__title
      margin: 0
      font-size: 1.1rem

    &__image
      background-size: cover
      background-position: center
      height: 320px
      +sp_up
        height: auto

    &__content
      padding: $mg
      box-sizing: border-box
      min-height: 100vh
      display: flex
      align-items: center
      position: relative

    &__body
      font-size: .9rem


