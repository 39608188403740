.home
  &-section
    /*
      Base
    /*---------------------------------------*/
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    &.is-astronomica
      .home-section__title
        img
          height: 80px

    /*
      Header and Title
    /*---------------------------------------*/
    &__header
      display: flex
      justify-content: center

    &__title
      line-height: 1
      margin-bottom: 0
      img
        height: 50px

    /*
      Logo 
    /*---------------------------------------*/
    &__logo
      position: absolute
      top: 50px
      left: 50px
      width: 200px

    /*
      Body 
    /*---------------------------------------*/
    &__body
      max-width: $maxWidth
      margin: $gutter*4 auto
      box-sizing: border-box
      color: white
      font-size: .9rem
      line-height: 1.6

    /*
      Read More 
    /*---------------------------------------*/
    &__readmore
      color: white
      font-weight: bold
      font-style: italic
      +transition

      &:hover
        text-decoration: none
        color: white
        opacity: .7

