.image
  object-fit: cover

  &.is-thumbnail
    width: 320px
    height: 320px

  &.is-480
    width: 480px
    height: 480px

  &.is-480x320
    width: 480px
    height: 320px

