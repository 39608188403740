/*
  layout
/*---------------------------------------*/
$mg: 60px
$gutter: $mg/4
$maxWidth: 960px

/*
  color
/*---------------------------------------*/
$black: #000
$white: #fff
$font: #2b2b2b
$primary: #3b78ad
$secondary: $font
$gray: #eceff1


/*
  font
  @src https://fonts.google.com/
/*---------------------------------------*/
$font-family: "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "ＭＳ ゴシック", sans-serif
$mincho: Ryumin Regular KL, "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif
$font-nueton: 'Nueton',serif
$font-tangerine: 'Tangerine', cursive
