.archive

  /*
    Header and Title 
  /*---------------------------------------*/
  &__header
    margin-bottom: $mg
  &__title
    text-align: center
    margin: 0



  /*
    Item 
  /*---------------------------------------*/
  &-item
    &__content
      padding: $gutter*2

    &__title
      font-size: 1.2rem

    &__datetime
      font-size: .7rem
      opacity: .7

    &__excerpt
      font-size: .8rem
      margin: $gutter 0

    &__link
      color: $font
      font-weight: bold
      font-style: italic
      font-size: .8rem
      +transition

      &:hover
        text-decoration: none
        color: $font
        opacity: .7
