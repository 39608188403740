.pagination
  display: flex
  justify-content: center

  .page-numbers
    color: $font
    font-size: .9rem

    &.current
      text-decoration: underline
