/*
  common 
/*---------------------------------------*/
html, body
  min-height: 100vh
body
  text-rendering: optimizeLegibility
  font-smoothing: antialiased
  -webkit-font-smoothing: antialiased
  color: $font

  /*
    mq
  /*---------------------------------------*/
  font-size: 14px
  line-height: 1.6
  +md_up
    font-size: 16px
    letter-spacing: 1px
    line-height: 2

html, body, h1, h2, h3, h4, h5, h6, div, p, span, li, a, td, th
  font-family: $mincho!important

/*
  util 
/*---------------------------------------*/
@mixin transition
  transition: .3s ease

@mixin box-shadow($dp: 1)
  box-shadow: 0 3px*$dp 6px*$dp -3px*$dp rgba($black, .8)


